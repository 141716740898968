(<template>
  <footer class="s-footer-mobile">
    <template v-for="(menu, index) in bottomMenu">
      <router-link v-if="menu.condition"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames">{{ menu.name }}</router-link>
    </template>
  </footer>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapState('s_DashboardStore', {
        newInvitation: (state) => state.newInvitation
      }),
      ...mapGetters('UserInfoStore', [
        'userIsSeller',
        'userHasInterpretation'
      ]),
      invitationMenuClasses() {
        return this.newInvitation ? 'mobile-link-footer mobile-link--new-invitation' : 'mobile-link-footer mobile-link--invitation';
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'ServerCalendar',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      projectsLink() {
        return this.$makeRoute({
          name: 'ServerAllProjects',
          query: {
            available: 'new',
            accepted: 'active'
          }
        });
      },
      bottomMenu() {
        return [
          {
            name: this.$gettext('Home'),
            type: 'router-link',
            classNames: 'mobile-link-footer mobile-link--home',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: true
          },
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'mobile-link-footer mobile-link--interpretation',
            action: this.$makeRoute({name: 'ServerAllAssignments', query: {view: 'current', sort: 1, page: 1}}),
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 'mobile-link-footer mobile-link--calendar',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'mobile-link-footer mobile-link--new-translation',
            action: this.projectsLink,
            condition: this.userIsSeller && this.userHasInterpretation
          },
          {
            name: this.$gettext('Invitations'),
            type: 'router-link',
            classNames: this.invitationMenuClasses,
            action: this.$makeRoute({name: 'ServerAllInvitations'}),
            condition: true
          }
        ];
      }
    }
  };
</script>

<style scoped src="@assets/css/footer_mobile.css"></style>
