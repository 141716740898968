(<template>
  <div class="viewers-bar">
    <viewer v-for="viewer in allViewers"
            :key="viewer.uid"
            :viewer="viewer" />
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import Viewer from '@/components/shared_components/current_viewers/Viewer';

  export default {
    components: {
      viewer: Viewer
    },
    computed: {
      ...mapState('ViewersStore', {
        viewersList: (state) => state.viewersList || [],
        viewers: (state) => state.viewers || {}
      }),
      foregroundViewersId() { return this.viewers.foreground || []; },
      backgroundViewersId() { return this.viewers.background || []; },
      backgroundViewers() {
        const res = [];
        this.viewersList.forEach((viewer) => {
          if (this.backgroundViewersId.includes(viewer.uid)) res.push({...viewer, status: 'inactive'});
        });
        return res;
      },
      foregroundViewers() {
        const res = [];
        this.viewersList.forEach((viewer) => {
          if (this.foregroundViewersId.includes(viewer.uid)) res.push({...viewer, status: 'active'});
        });
        return res;
      },
      allViewers() { return this.foregroundViewers.concat(this.backgroundViewers) || []; }
    }
  };
</script>

<style scoped>
  .viewers-bar {
    display: flex;
    justify-content: flex-end;
    min-height: 41px;
    padding: 5px 20px 0;
  }
</style>
