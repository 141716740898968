(<template>
  <footer class="b-footer-mobile">
    <router-link :to="$makeRoute({name: 'BuyerHome'})"
                 class="mobile-link-footer mobile-link--home">{{ $gettext('Home') }}</router-link>
    <router-link :to="$makeRoute({name: 'BuyerAllAssignments', query: {view: 'current', sort: 1, page: 1}})"
                 class="mobile-link-footer mobile-link--interpretation">{{ $gettext('Interpretation') }}</router-link>
    <button class="mobile-link-footer book-btn-mobile"
            @click="toggleMobileDrawer('book')">
      {{ $gettext('Book') }}
    </button>
    <router-link :to="$makeRoute({name: 'BuyerAllProjects'})"
                 class="mobile-link-footer mobile-link--new-translation">{{ $pgettext('translation', 'Translation') }}</router-link>
    <button class="mobile-btn--burger mobile-link-footer"
            :class="{'burger-btn--active': isMenuOpen}"
            @click="toggleMobileDrawer('menu')">
      {{ $gettext('Menu') }}
    </button>
  </footer>
</template>)

<script>
  export default {
    computed: {
      isMenuOpen() { return this.$store.getters.mobileDrawerType === 'menu' && this.$store.getters.showMobileDrawer; },
    },
    methods: {
      toggleMobileDrawer(type) {
        this.$store.commit('toggleMobileDrawer', {force: false, drawerType: type});
      }
    }
  };
</script>

<style scoped src="@assets/css/footer_mobile.css"></style>
