(<template>
  <div>
    <div class="banner__wrapper">
      <div class="banner__message"
           v-html="message"></div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'accountStatusExpiresAt'
      ]),
      supportLink() {
        return 'https://support.salita.no/article/479-tolkestatus';
      },
      message() {
        const pausedDate = this.accountStatusExpiresAt;
        const template = this.$gettext('Your account is paused. You will not receive new invitations before %{pausedDate}. <br>This happens automatically when you have not shown up for several confirmed assignments, or have not been available at the agreed upon time for confirmed phone assignments <a class="sk-link sk-link--gray-bg" target=\"_blank\" href="%{ link }">Click here to learn more</a>. Contact us at <a href="mailto:%{email}">%{email}</a> providing a reason why you did not answer/meet up or wait until %{pausedDate}.');
        return this.$gettextInterpolate(template, {
          pausedDate,
          link: this.supportLink,
          email: this.$getString('domainData', 'subSupportEmail')
        });
      }
    }
  };
</script>

<style scoped>
  .banner__wrapper {
    position: relative;
    margin: 1rem;
    padding: 10px 10px 10px 35px;
    border-radius: 3px;
    background-color: rgba(229, 35, 34, 0.7);
    font-weight: bold;
    font-size: 12px;
  }

  .banner__message::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(~@assets/imgs/undefined_imgs/warning-icon.svg);
    background-position: 50% 50%;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }
</style>
