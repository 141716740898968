<template>
  <div class="banner warning-banner"
       :class="{ 'warning-banner--sticky': assignmentLink }">
    <p class="warning-banner__message"
       v-html="message"></p>
    <router-link v-if="assignmentLink"
                 :to="assignmentLink"
                 class="warning-banner__link">{{ linkText }}</router-link>
  </div>
</template>

<script>
  export default {
    props: {
      assignmentLink: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    },
    computed: {
      linkText() {
        return this.$gettext('Go to assignment');
      }
    }
  };
</script>

<style scoped>
.warning-banner {
  margin: 15px;
  padding: 15px 15px 15px 45px;
  border: 1px solid #fede93;
  border-radius: 8px;
  background-color: #fffaed;
  background-image: url(~@assets/imgs/salita/undefined_imgs/warning_icon.svg);
  background-position: 17px center;
  background-size: 17px;
  background-repeat: no-repeat;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  word-break: normal;
}

.warning-banner--sticky {
  position: sticky;
  top: 70px;
  z-index: 5;
}

.warning-banner__message {
  display: inline-flex;
}

.warning-banner__link {
  margin-left: 10px;
  font-weight: bold;
  text-decoration: underline;
}
</style>
