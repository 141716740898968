(<template>
  <footer class="b-footer">
    <div class="b-footer__desktop-wrapper">
      <div class="b-footer__logo"></div>
      <p class="b-footer__copy">{{ copyright }}</p>
      <locale-select class="b-footer__select" />
      <div class="b-footer__external-links-cont">
        <a target="_blank"
           :href="privacyLink"
           class="b-footer__external-link">{{ $gettext('Privacy') }}</a>
        <a target="_blank"
           :href="termsLink"
           class="b-footer__external-link">{{ $gettext('Terms & Conditions') }}</a>
        <a class="b-footer__external-icon-link b-footer__external-icon-link--twitter"
           target="_blank"
           :href="twitterLink"></a>
        <a class="b-footer__external-icon-link b-footer__external-icon-link--facebook"
           target="_blank"
           :href="facebookLink"></a>
        <a class="b-footer__external-icon-link b-footer__external-icon-link--linkedin"
           target="_blank"
           :href="linkedinLink"></a>
      </div>
    </div>
    <div class="b-footer__mobile-wrapper">
      <template v-for="(menu,index) in bottomMenu">
        <router-link v-if="menu.type === 'router-link' && menu.condition"
                     :key="index"
                     :to="menu.action"
                     :class="menu.classNames">{{ menu.name }}</router-link>
      </template>
      <button class="burger-btn"
              :class="{'is-opened-mobile-nav burger-btn--open': mobileNavState, 'is-sub-page': isSubPage}"
              @click="toggleMobileNav">
        <span class="burger-btn__first-line"></span>
        <span class="burger-btn__second-line"></span>
        <span class="burger-btn__third-line"></span>
        <span class="burger-btn__fourth-line"></span>
        {{ $gettext('My account') }}
      </button>
    </div>
  </footer>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        currencyList: [{
          id: 1,
          name: 'NOK'
        }]
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCurrencyId',
        'userIsEnterpriseMember',
        'allowInterpretationServices',
        'allowNewTranslationServices'
      ]),
      bottomMenu() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--interpretation',
            action: this.assignmentsLink,
            condition: true
          },
        ];
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ManagerAllAssignments',
          query: {
            view: 'urgent',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth;
      },
      smallScreen() {
        return this.clientWidth < 768;
      },
      mobileNavState() {
        return this.$store.getters.showMobileNav;
      },
      isSubPage() {
        return this.$store.state.subPage;
      },
      copyright() { return this.$getString('footer', 'copyright'); },
      facebookLink() { return this.$getString('social', 'facebookLink'); },
      twitterLink() { return this.$getString('social', 'twitterLink'); },
      linkedinLink() { return this.$getString('social', 'linkedinLink'); },
      privacyLink() { return this.$getString('domainData', 'privacyLink'); },
      termsLink() { return this.$getString('domainData', 'termLink'); },
    },
    methods: {
      toggleMobileNav() {
        this.$store.commit('toggleMobileNav');
      }
    }
  };
</script>
<style src="@assets/css/navigation_footer.css" scoped></style>
