(<template>
  <banner :message="bannerMessage"
          class="new-mobile-apps-banner">
    <div class="new-mobile-apps-banner__link-wrapper">
      <a v-if="iosAppLink"
         class="sk-link new-mobile-apps-banner__ios-link"
         target="_blank"
         :href="iosAppLink"></a>
      <a v-if="androidAppLink"
         class="sk-link new-mobile-apps-banner__android-link"
         target="_blank"
         :href="androidAppLink"></a>
    </div>
  </banner>
</template>)

  <script>
  import Banner from '@/components/shared_components/banners/Banner';
  import constants from '@/modules/constants';

  export default {
    components: {
      banner: Banner
    },
    computed: {
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      bannerMessage() {
        // We check only Android app link presence here because so far we had issue with account removing
        // On Google Play Store and never on iOS App Store.
        if (this.androidAppLink) {
          return this.isMobileView
            ? this.$gettext('We have a new mobile app! Download the new app from App Store or Google Play today')
            : this.$gettext('We have a new mobile app! Get access to all your assignments on the go by downloading the new app from App Store or Google Play.');
        } else {
          return this.isMobileView
            ? this.$gettext('We have a new mobile app! Download the new app from App Store.')
            : this.$gettext('We have a new mobile app! Get access to all your assignments on the go by downloading the new app from App Store.');
        }
      },
      iosAppLink() { return constants.IOS_APP_LINK; },
      androidAppLink() { return constants.ANDROID_APP_LINK; },
    }
  };
  </script>

<style>
.new-mobile-apps-banner.banner {
  padding: 10px 15px;
}

</style>

  <style scoped>
    .new-mobile-apps-banner {
      width: calc(100% - 30px);
      margin: 15px auto 0;
    }

    .new-mobile-apps-banner__link-wrapper {
      display: flex;
    }

    .new-mobile-apps-banner__ios-link,
    .new-mobile-apps-banner__android-link {
      width: 100px;
      height: 30px;
      margin-left: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .new-mobile-apps-banner__ios-link {
      background-image: url(~@assets/imgs/app_store_badges/apple-store-badge.png);
    }

    .new-mobile-apps-banner__android-link {
      background-image: url(~@assets/imgs/app_store_badges/google-play-badge.png);
    }

    @media (max-width: 767px) {
      .new-mobile-apps-banner {
        width: 100%;
      }

      .new-mobile-apps-banner__link-wrapper {
        margin: 5px;
      }
    }
  </style>
