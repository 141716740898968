import { render, staticRenderFns } from "./m_Footer.vue?vue&type=template&id=33aba475&scoped=true&"
import script from "./m_Footer.vue?vue&type=script&lang=js&"
export * from "./m_Footer.vue?vue&type=script&lang=js&"
import style0 from "@assets/css/navigation_footer.css?vue&type=style&index=0&id=33aba475&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33aba475",
  null
  
)

export default component.exports