(<template>
  <banner :message="bannerMessage"
          class="payout-method-banner">
    <button class="sk-link payout-method-banner__link"
            @click="openPayoutModal">{{ $gettext('Click here to set up your account') }}</button>
  </banner>
</template>)

<script>
  import Banner from '@/components/shared_components/banners/Banner';

  export default {
    components: {
      banner: Banner
    },
    computed: {
      bannerMessage() {
        return this.$gettext('Please tell us how you would like to be paid for your assignments.');
      }
    },
    methods: {
      openPayoutModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'payout-info-modal',
          width: 600,
          data: {context: this}
        });
      }
    }
  };
</script>

<style scoped>
  .payout-method-banner {
    width: calc(100% - 30px);
    margin: 15px auto 0;
  }

  .payout-method-banner__link {
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    .payout-method-banner {
      width: 100%;
    }
  }
</style>
