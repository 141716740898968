// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/app_store_badges/apple-store-badge.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/app_store_badges/google-play-badge.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.new-mobile-apps-banner[data-v-6ef867da] {\n  width: calc(100% - 30px);\n  margin: 15px auto 0;\n}\n.new-mobile-apps-banner__link-wrapper[data-v-6ef867da] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.new-mobile-apps-banner__ios-link[data-v-6ef867da],\n.new-mobile-apps-banner__android-link[data-v-6ef867da] {\n  width: 100px;\n  height: 30px;\n  margin-left: 10px;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.new-mobile-apps-banner__ios-link[data-v-6ef867da] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.new-mobile-apps-banner__android-link[data-v-6ef867da] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@media (max-width: 767px) {\n.new-mobile-apps-banner[data-v-6ef867da] {\n    width: 100%;\n}\n.new-mobile-apps-banner__link-wrapper[data-v-6ef867da] {\n    margin: 5px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
