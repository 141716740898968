// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/warning-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.banner__wrapper[data-v-b1f79d6e] {\n  position: relative;\n  margin: 1rem;\n  padding: 10px 10px 10px 35px;\n  border-radius: 3px;\n  background-color: rgba(229, 35, 34, 0.7);\n  font-weight: bold;\n  font-size: 12px;\n}\n.banner__message[data-v-b1f79d6e]::before {\n  content: '';\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  display: block;\n  width: 15px;\n  height: 15px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 15px auto;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
