(<template>
  <div :class="{'in-progress': progressActive}"
       class="banner is-progress-bar">
    <p class="banner__message"
       v-html="message"></p>
    <slot></slot>
  </div>
</template>)

<script>
  export default {
    props: {
      message: {
        type: String,
        default: ''
      },
      progressActive: {
        type: [String, Boolean],
        default: ''
      }
    }
  };
</script>

<style scoped>
  .banner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
    padding: 15px;
    background-color: #fd6;
    box-shadow: 0 2px 5px 0 rgba(0, 34, 102, 0.3);
  }

  .banner__message {
    align-items: left;
    font-weight: bold;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    .banner__message {
      font-size: 12px;
    }
  }

  @media (max-width: 550px) {
    .banner {
      flex-direction: column;
    }
  }
</style>
