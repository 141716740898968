(<template>
  <div class="godmode-info-banner">
    <div class="godmode-info-banner__user-info-cont">
      <router-link v-if="userIsEnterpriseMember"
                   :to="$makeRoute({name: 'BuyerDepartments'})"
                   class="godmode-info-banner__item">{{ enterpriseName }}</router-link>
      <router-link v-if="userIsEnterpriseMember"
                   :to="$makeRoute({name: 'BuyerEmployees'})"
                   class="godmode-info-banner__item">{{ fullName }}</router-link>
      <interpreter-blocked-status v-if="isBlockedByStatus && userIsInterpreter"
                                  :interpreter-data="additionalUserInfo"
                                  class="godmode-info-banner__status-btn" />
      <span v-if="!userIsEnterpriseMember"
            class="godmode-info-banner__item">{{ fullName }}</span>
      <a v-if="userEmail"
         :href="`mailto:${userEmail}`"
         class="godmode-info-banner__item">{{ userEmail }}</a>
      <a v-if="userFullPhone"
         :href="`tel:${userFullPhone}`"
         class="godmode-info-banner__item js-copy-to-clipboard">{{ userFullPhone }}</a>
      <span v-if="enterpriseMemberBookingRef"
            class="godmode-info-banner__item">{{ enterpriseMemberBookingRef }}</span>
      <span v-if="supplierNumber"
            class="godmode-info-banner__item">{{ supplierNumberText }}</span>
    </div>
    <a v-if="userIsEnterpriseMember"
       :href="adminLink"
       target="_blank"
       class="sk-btn sk-btn--default godmode-info-banner__btn">{{ $gettext('Return to Admin') }}</a>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import constants from '@/modules/constants';
  import InterpreterBlockedStatus from '@/components/interpreters_components/InterpreterBlockedStatus';

  export default {
    components: {
      'interpreter-blocked-status': InterpreterBlockedStatus
    },
    data() {
      return {
        adminLink: `${constants.ADMIN_PATH}/radmin/enterprise`
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsEnterpriseMember',
        'fullName',
        'userEmail',
        'userFullPhone',
        'enterpriseName',
        'enterpriseMemberBookingRef',
        'supplierNumber',
        'userIsInterpreter',
        'additionalUserInfo'
      ]),
      supplierNumberText() {
        const template = this.$gettext('supplier number: %{supplierNumber}');
        return this.$gettextInterpolate(template, {supplierNumber: this.supplierNumber});
      },
      isBlockedByStatus() {
        return helpers.interpreterInfo.іsBlockedInterpreter(this.additionalUserInfo);
      },
    }
  };
</script>

<style scoped>
  .godmode-info-banner {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    background-color: #fd6;
  }

  .godmode-info-banner__user-info-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    padding-right: 10px;
  }

  .godmode-info-banner__btn {
    width: auto;
    min-width: 130px;
    margin-left: auto;
    padding: 0 10px;
  }

  .godmode-info-banner__item {
    display: block;
    margin-left: 5px;
    font-weight: bold;
  }

  .godmode-info-banner__item::after {
    content: ',';
  }

  .godmode-info-banner__item:last-child::after {
    content: '';
  }

  .godmode-info-banner__status-btn {
    padding-left: 10px;
  }

  @media (max-width: 767px) {
    .godmode-info-banner {
      overflow: auto;
      height: 90px;
      font-size: 11px;
    }

    .godmode-info-banner__btn {
      min-width: 110px;
      padding: 0 5px;
      font-size: 12px;
    }

    .godmode-info-banner__item {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
