(<template>
  <div class="viewer">
    <div class="viewer__avatar-wrapper">
      <sk-tooltip class="viewer__tooltip">
        <tt-avatar :avatar="viewer.avatar"
                   class="viewer__row viewer__row--image" />
        <p v-if="viewer.name"
           class="viewer__row viewer__row--title">{{ viewer.name }}</p>
        <p v-if="viewer.phone"
           class="viewer__row">{{ `${viewer.phone.code}${viewer.phone.number}` }}</p>
        <a v-if="viewer.email"
           :href="`mailto:${viewer.email}`"
           class="viewer__row">{{ viewer.email }}</a>
      </sk-tooltip>
      <tt-avatar :avatar="viewer.avatar"
                 :class="{'inactive': viewer.status === 'inactive'}"
                 class="viewer__avatar" />
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      viewer: {
        type: Object,
        default() {
          return {};
        }
      }
    }
  };
</script>

<style>
  .viewer .sk-tooltip__text {
    top: 35px;
    width: auto;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }
</style>

<style scoped>
  .viewer {
    position: relative;
    margin-left: 5px;
  }

  .viewer__tooltip {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: block;
  }

  .viewer__avatar {
    width: 32px;
    height: 32px;
    border: 2px solid #fff;
  }

  .viewer__avatar.inactive {
    opacity: 0.3;
  }

  .viewer__row {
    display: block;
    padding-left: 40px;
    font-size: 12px;
    text-align: left;
  }

  .viewer__row--title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }

  .viewer__row--image {
    position: absolute;
    width: 35px;
    height: 35px;
    padding: 0;
  }
</style>
