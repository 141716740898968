(<template>
  <banner :message="bannerMessage"
          class="timezone-banner">
    <div class="timezone-banner__content-cont">
      <button class="sk-btn sk-btn--default timezone-banner__btn"
              @click="updateServerTimezone">{{ $gettext('Yes') }}</button>
    </div>
  </banner>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import Banner from '@/components/shared_components/banners/Banner';

  export default {
    components: {
      banner: Banner
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userServerTimeZoneName',
        'userUid'
      ]),
      localTimezone() {
        return this.$moment.tz.guess() || this.$moment().format('Z');
      },
      bannerMessage() {
        const template = this.$gettext('Your platform timezone is set to <b>%{serverTimezone}</b> but your computer/phone is set to another timezone <b>%{localTimezone}</b>. Should we update your platform timezone to <b>%{localTimezone}</b>?');
        return this.$gettextInterpolate(template, {serverTimezone: this.userServerTimeZoneName, localTimezone: this.localTimezone});
      }
    },
    methods: {
      updateServerTimezone() {
        this.$store.dispatch('updateTimezone', this.$moment().local()).then(() => {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
            this.$emit('onupdated');
          });
        });
      }
    }
  };
</script>

<style scoped>
  .timezone-banner {
    width: calc(100% - 30px);
    margin: 15px auto 0;
  }

  .timezone-banner__content-cont {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  }

  .timezone-banner__btn {
    display: inline-block;
    flex-shrink: 0;
    width: auto;
    padding: 0 15px;
  }

  @media (max-width: 767px) {
    .timezone-banner {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    .timezone-banner__content-cont {
      width: 100%;
      margin: 15px 0 0;
    }
  }
</style>
