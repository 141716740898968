(<template>
  <div class="language-dropdown">
    <button class="language-dropdown__btn"
            :class="{'language-dropdown__btn--active': langDropdownActive}"
            @click="showTranslationDropdown">{{ currentValue.name }}</button>
    <div class="language-dropdown__arrow-icon subnav-link__arrow-icon"></div>
    <div class="language-dropdown__overlay"
         :class="{'language-dropdown__overlay--active': langDropdownActive}"></div>
    <div v-if="langDropdownActive"
         v-click-outside="closeSelector"
         class="language-dropdown__list dropdown-list">
      <div class="dropdown-list__mobile-header">
        <h3>{{ $gettext('Language') }}</h3>
        <button class="dropdown-list__close-btn"
                @click="closeSelector"></button>
      </div>
      <div class="dropdown-list__wrapper">
        <button v-for="(lang, index) in langs"
                :key="index"
                class="dropdown-list__item"
                :class="{'dropdown-list__item--active': lang.id === $language.current}"
                @click="setLang(lang.id)">
          <span class="dropdown-list__name">{{ lang.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>)

<script>
  import constants from '@/modules/constants';
  import {mapMutations} from 'vuex';

  export default {
    data() {
      return {
        langDropdownActive: false
      };
    },
    computed: {
      langs() {
        return [
          {id: 'en', name: 'English'},
          {id: 'nb', name: 'Norsk'}
        ];
      },
      currentValue() {
        for (const lang of this.langs) {
          if (lang.id == this.$language.current) {
            return lang;
          }
        }
      }
    },
    methods: {
      ...mapMutations('UserInfoStore', ['setLocale']),
      setDomain() {
        if (window.location.hostname.includes('herokuapp.com')) return window.location.hostname;
        if (window.location.hostname.includes('salita.no')) return '.salita.no';
        return constants.COOKIE_PATH || '.tikktalk.com';
      },
      setLang(id) {
        if (id != this.$language.current) {
          const cookieParams = {path: '/', expires: constants.COOKIE_EXPIRATION_TIME};
          this.$language.current = id;
          if (window.location.hostname !== 'localhost') {
            cookieParams.domain = this.setDomain(); // ie reject cookies with domain: 'localhost'
          }
          this.$cookieManager.setCookie('locale', this.$language.current, cookieParams);
          this.$cookieManager.setCookie('serverLocale', this.$language.current, cookieParams);
          this.$fetcher.setLocale(id, this.$store.state.TTAuthStore.loggedIn).then(() => {
            this.setLocale(this.$language.current);
          });
          this.langDropdownActive = false;
        }
      },
      showTranslationDropdown() {
        this.langDropdownActive = !this.langDropdownActive;
      },
      closeSelector() {
        this.langDropdownActive = false;
      }
    },
    beforeMount() {
      for (const lang of this.langs) {
        if (lang.id == this.$route.params.lang) {
          this.setLang(lang.id, lang.name);
        }
      }
    }
  };
</script>

<style scoped>
.language-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 16px;
}

.language-dropdown__btn {
  height: 100%;
  padding-right: 10px;
  padding-left: 28px;
  border-bottom: 2px transparent solid;
  background-image: url(~@assets/imgs/salita/dashboard_icons/lang_icon.svg);
  background-position: 5px 50%;
  background-size: 20px auto;
  background-repeat: no-repeat;
  color: #000;
  font-weight: 400;
}

.language-dropdown__arrow-icon {
  display: none;
}

.language-dropdown__btn--active {
  border-bottom: 2px #000 solid;
}

.language-dropdown__btn:hover {
  background-color: #f4f5f7;
  transition: ease-in-out 0.125s;
}

.language-dropdown__list {
  position: absolute;
  top: 65px;
  z-index: 13;
  width: 160px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

.language-dropdown__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  display: none;
  width: 100%;
  height: 100%;
  background-color: #646d8c80;
}

.dropdown-list__mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 15px;
}

.dropdown-list__close-btn {
  width: 12px;
  height: 12px;
  background-image: url(~@assets/imgs/salita/dashboard_icons/close_x_mobile.svg);
  background-position: center center;
}

.dropdown-list__item--active {
  background-image: url(~@assets/imgs/salita/dashboard_icons/check_mark_icon.svg);
  background-position: 95% center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .language-dropdown {
    margin: 0;
    padding: 8px 11px;
  }

  .language-dropdown__overlay--active {
    display: block;
  }

  .language-dropdown__btn {
    padding-left: 30px;
    border-bottom: none;
    background-position: 0 50%;
  }

  .language-dropdown__arrow-icon {
    display: block;
  }

  .language-dropdown__list {
    position: fixed;
    top: auto;
    bottom: 76px;
    left: 0;
    z-index: 13;
    width: 100%;
    height: 175px;
    border-radius: 16px 16px 0 0;
    background: #fff;
  }
}
</style>
