<template>
  <div class="book-btn-menu">
    <button class="book-btn"
            :class="{'book-btn--active': bookMenuActive}"
            @click="showBookMenu">{{ $gettext('Book') }}</button>
    <div v-if="bookMenuActive"
         v-click-outside="closeDropdown"
         class="menu-list">
      <div v-for="(link, index) in links"
           :key="index"
           class="menu-list__item menu-link"
           @click="handleLinkClick(link)">
        <div class="menu-link__image"
             :class="link.iconClass"></div>
        <div class="menu-link__inner-wrapper">
          <span class="menu-link__subtitle">{{ link.subtitle }}</span>
          <span class="menu-link__text">{{ link.text }}</span>
        </div>
        <div class="menu-link__arrow-icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import constants from '@/modules/constants';

  export default {
    data() {
      return {
        bookMenuActive: false
      };
    },
    computed: {
      postTranslationLink() { return this.$isGodmode() ? `${constants.CONNECT_PATH?.replace(/\/+$/, '')}/create-translation-order?customeruid=${this.$store.getters['UserInfoStore/userUid']}` : this.$makeRoute({name: 'BuyerPostTranslationProject'}); },
      links() {
        return [
          {
            subtitle: this.$gettext('Interpreter'),
            text: this.$gettext('Speech Language Interpreter'),
            type: 'router-link',
            iconClass: 'book-interpreter-icon',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            subtitle: this.$gettext('Translator'),
            text: this.$gettext('Documents, files, etc.'),
            type: 'router-link',
            iconClass: 'order-translation-icon',
            action: this.postTranslationLink
          },
          {
            subtitle: this.$gettext('Sign language translator'),
            text: this.$gettext('Gestures, facial expressions and body language'),
            type: 'router-link',
            iconClass: 'book-sing-lang-translator',
            action: this.$makeRoute({name: 'BuyerOtherServiceAssignment', params: {service: 1}})
          },
          {
            subtitle: this.$gettext('Other services'),
            text: this.$gettext('Subtitling, conference interpreting, etc.'),
            type: 'router-link',
            iconClass: 'other-service',
            action: this.$makeRoute({name: 'BuyerOtherServiceAssignment', params: {service: 'other'}})
          }
        ];
      }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showBookMenu() {
        this.bookMenuActive = !this.bookMenuActive;
      },
      closeDropdown() {
        this.bookMenuActive = false;
      },
      handleLinkClick(link) {
        const action = typeof link.action === 'function' ? link.action() : link.action;
        typeof action === 'string' ? window.open(action, '_blank') : this.$router.push(action);
      },
    }
  };
</script>

<style scoped>
.book-btn-menu {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.book-btn {
  height: 35px;
  padding: 0 30px 0 10px;
  border-radius: 3px;
  background-color: var(--color-primary);
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_down_white.svg);
  background-position: calc(100% - 10px) center;
  background-size: 19px auto;
  background-repeat: no-repeat;
  color: #fff;
}

.book-btn--active {
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_up_white.svg);
}

.menu-list {
  position: absolute;
  top: 60px;
  z-index: 3;
  width: 400px;
  padding: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}
</style>
