(<template>
  <div class="nav-dropdown">
    <button class="nav-dropdown__btn"
            :class="{'nav-dropdown__btn--active': dropdownActive}"
            @click="showDropdown">{{ dropdownSubtitle }}</button>
    <div v-if="dropdownActive"
         v-click-outside="closeDropdown"
         class="dropdown-list">
      <div class="dropdown-list__wrapper">
        <router-link v-for="(link, index) in filteredLinks"
                     :key="index"
                     :to="link.action"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      dropdownSubtitle: {
        type: String,
        default: ''
      },
      links: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        dropdownActive: false
      };
    },
    computed: {
      filteredLinks() { return this.links.filter((link) => link.condition); }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showDropdown() {
        this.dropdownActive = !this.dropdownActive;
      },
      closeDropdown() {
        this.dropdownActive = false;
      }
    }
  };
</script>
