(<template>
  <footer class="b-footer">
    <div class="b-footer__desktop-wrapper">
      <div class="b-footer__logo"></div>
      <p class="b-footer__copy">{{ copyright }}</p>
      <locale-select class="b-footer__select" />
      <jobs-scope-select v-if="userIsEnterpriseMember"
                         class="b-footer__select" />
      <div class="b-footer__external-links-cont">
        <a target="_blank"
           :href="privacyLink"
           class="b-footer__external-link">{{ $gettext('Privacy') }}</a>
        <a target="_blank"
           :href="termsLink"
           class="b-footer__external-link">{{ $gettext('Terms & Conditions') }}</a>
        <a class="b-footer__external-icon-link b-footer__external-icon-link--twitter"
           target="_blank"
           :href="twitterLink"></a>
        <a class="b-footer__external-icon-link b-footer__external-icon-link--facebook"
           target="_blank"
           :href="facebookLink"></a>
        <a class="b-footer__external-icon-link b-footer__external-icon-link--linkedin"
           target="_blank"
           :href="linkedinLink"></a>
      </div>
    </div>
    <div class="b-footer__mobile-wrapper">
      <template v-for="(menu,index) in bottomMenu">
        <router-link v-if="menu.type === 'router-link' && menu.condition"
                     :key="index"
                     :to="menu.action"
                     :class="menu.classNames">{{ menu.name }}</router-link>
      </template>
      <button class="burger-btn"
              :class="{'is-opened-mobile-nav burger-btn--open': mobileNavState, 'is-sub-page': isSubPage}"
              @click="toggleMobileNav">
        <span class="burger-btn__first-line"></span>
        <span class="burger-btn__second-line"></span>
        <span class="burger-btn__third-line"></span>
        <span class="burger-btn__fourth-line"></span>
        {{ $gettext('My account') }}
      </button>
    </div>
  </footer>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import JobsScopeSelect from '@/components/shared_components/JobsScopeSelect';

  export default {
    components: {
      'jobs-scope-select': JobsScopeSelect
    },
    data() {
      return {
        currencyList: [{
          id: 1,
          name: 'NOK'
        }]
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotEnterpriseMember',
        'userCurrencyId',
        'userIsEnterpriseMember',
        'allowInterpretationServices',
        'allowNewTranslationServices'
      ]),
      bottomMenu() {
        if (this.allowInterpretationServices === true && this.allowNewTranslationServices === true) {
          return this.onBothBottomMenus;
        } else if (this.allowInterpretationServices === true && this.allowNewTranslationServices === false) {
          return this.interpretationBottomMenus;
        } else if (this.allowInterpretationServices === false && this.allowNewTranslationServices === true) {
          return this.translationBottomMenus;
          // if flags are not defined or both are false ==> fallback to default
        } else {
          return this.interpretationBottomMenus;
        }
      },
      onBothBottomMenus() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--interpretation',
            action: {name: 'BuyerHome'},
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--new-translation',
            action: {name: 'BuyerAllProjects'},
            condition: true
          },
          {
            name: this.$gettext('Book an interpreter'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--book-interpreter',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'}),
            condition: true
          },
          {
            name: this.$gettext('Order translation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--order-translation',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
            condition: true
          }
        ];
      },
      interpretationBottomMenus() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--interpretation',
            action: {name: 'BuyerHome'},
            condition: true
          },
          {
            name: this.$gettext('Book an interpreter'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--book-interpreter',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'}),
            condition: true
          },
          {
            name: this.$gettext('Interpreters'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--interpreters',
            action: this.capacityLink,
            condition: this.userIsEnterpriseMember
          }
        ];
      },
      translationBottomMenus() {
        return [
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--new-translation',
            action: {name: 'BuyerAllProjects'},
            condition: true
          },
          {
            name: this.$gettext('Order translation'),
            type: 'router-link',
            classNames: 'mobile-link mobile-link--order-translation',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
            condition: true
          }
        ];
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD'),
            page: 1
          }
        });
      },
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth;
      },
      smallScreen() {
        return this.clientWidth < 768;
      },
      postPageRoute() {
        return this.smallScreen && this.$store.getters.isNorway && this.userIsNotEnterpriseMember ? 'BuyerPostTabs' : 'BuyerSelectAssignmentType';
      },
      mobileNavState() {
        return this.$store.getters.showMobileNav;
      },
      isSubPage() {
        return this.$store.state.subPage;
      },
      copyright() { return this.$getString('footer', 'copyright'); },
      facebookLink() { return this.$getString('social', 'facebookLink'); },
      twitterLink() { return this.$getString('social', 'twitterLink'); },
      linkedinLink() { return this.$getString('social', 'linkedinLink'); },
      privacyLink() { return this.$getString('domainData', 'privacyLink'); },
      termsLink() { return this.$getString('domainData', 'termLink'); },
    },
    methods: {
      toggleMobileNav() {
        this.$store.commit('toggleMobileNav');
      },
    }
  };
</script>

<style>
  .b-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .input-focused .b-footer {
    position: relative;
    margin-bottom: -50px;
  }

  .b-footer__desktop-wrapper {
    display: flex;
    width: 100%;
    min-height: 50px;
  }

  .b-footer__mobile-wrapper {
    display: none;
  }

  .b-footer__logo {
    display: block;
    width: 50px;
    background-image: url(~@assets/imgs/logos/single_logo_gray.svg);
    background-position: 50% 50%;
    background-size: 20px auto;
    background-repeat: no-repeat;
  }

  .b-footer__copy {
    display: inline-block;
    align-self: center;
    width: auto;
    padding-right: 25px;
    color: #a7acbe;
    font-size: 12px;
  }

  .b-footer__select,
  .b-footer__select .sk-select {
    align-self: center;
    height: 35px;
    border: 0;
    color: #a7acbe;
    text-align: right;
  }

  .b-footer__select {
    width: 110px;
  }

  .b-footer__select:hover {
    background-color: #f0ecf6;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  }

  .b-footer__select .sk-select__value {
    background-color: transparent;
    font-size: 12px;
    line-height: 33px;
  }

  .b-footer__select:hover .sk-select__value {
    color: #000;
  }

  .b-footer__select .sk-select__typing-input {
    background-color: transparent;
    color: #000;
  }

  .b-footer__select .sk-select__arrow {
    height: 35px;
    background-color: transparent;
  }

  .b-footer__select .sk-select__arrow::before,
  .b-footer__select .sk-select__arrow::after {
    border-top-color: #a7acbe;
  }

  .b-footer__select .sk-select__list {
    top: auto;
    bottom: 100%;
    border: 0;
    color: #000;
    font-size: 12px;
  }

  .b-footer__select .sk-select__list-item {
    padding-top: 14px;
  }

  .b-footer__external-links-cont {
    display: flex;
    margin-left: auto;
    padding-right: 70px;
    color: #a7acbe;
    font-size: 12px;
  }

  .b-footer__external-link {
    display: inline-block;
    align-self: center;
    width: auto;
    padding: 0 12px;
    text-align: center;
  }

  .b-footer__external-link:hover,
  .b-footer__external-link:focus {
    color: #000;
    text-decoration: underline;
  }

  .b-footer__external-icon-link {
    position: relative;
    display: inline-block;
    width: 44px;
  }

  .b-footer__external-icon-link::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 7px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .b-footer__external-icon-link:hover::before,
  .b-footer__external-icon-link:focus::before {
    background-color: #f0ecf6;
  }

  .b-footer__external-icon-link--twitter::before {
    background-image: url(~@assets/imgs/social_links_icons/twitter_footer_icon.svg);
    background-position: 50% 65%;
    background-size: 16px auto;
  }

  .b-footer__external-icon-link--facebook::before {
    background-image: url(~@assets/imgs/social_links_icons/facebook_footer_icon.svg);
    background-size: 10px auto;
  }

  .b-footer__external-icon-link--linkedin::before {
    background-image: url(~@assets/imgs/social_links_icons/linkedin_footer_icon.svg);
    background-size: 15px auto;
  }

  .b-footer__external-icon-link--twitter:hover::before,
  .b-footer__external-icon-link--twitter:focus::before {
    background-image: url(~@assets/imgs/social_links_icons/twitter_footer_icon_black.svg);
    background-position: 50% 50%;
    background-size: 16px auto;
  }

  .b-footer__external-icon-link--facebook:hover::before,
  .b-footer__external-icon-link--facebook:focus::before {
    background-image: url(~@assets/imgs/social_links_icons/facebook_footer_icon_black.svg);
    background-size: 8px auto;
  }

  .b-footer__external-icon-link--linkedin:hover::before,
  .b-footer__external-icon-link--linkedin:focus::before {
    background-image: url(~@assets/imgs/social_links_icons/linkedin_footer_icon_black.svg);
    background-size: 15px auto;
  }

  @media (max-width: 767px) {
    .b-footer {
      bottom: -60px;
      min-height: 130px;
    }

    .input-focused .b-footer {
      bottom: 0;
      min-height: initial;
    }

    .b-footer__desktop-wrapper {
      display: none;
    }

    .b-footer__mobile-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      min-height: 50px;
      color: #000;
    }

    .is-sub-page.is-opened-mobile-nav {
      color: #646d8c;
      color: var(--color-primary);
    }

    .is-sub-page.is-opened-mobile-nav span {
      background-color: #646d8c;
      background-color: var(--color-primary);
    }
  }
</style>
