// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/dashboard_icons/close_x_mobile.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.mobile-drawer[data-v-9f5baaf6] {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 11;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background-color: #646d8c80;\n}\n.mobile-drawer__wrapper[data-v-9f5baaf6] {\n  margin-top: auto;\n  margin-bottom: 76px;\n  border-radius: 8px 8px 0 0;\n  background: #fff;\n  -webkit-box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);\n          box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);\n}\n.mobile-drawer__header[data-v-9f5baaf6] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  padding: 24px 15px;\n}\n.mobile-drawer__subtitle[data-v-9f5baaf6] {\n  padding: 8px 16px;\n}\n.mobile-drawer__body[data-v-9f5baaf6] {\n  margin: 0 8px 8px;\n}\n.mobile-drawer__close-btn[data-v-9f5baaf6] {\n  width: 12px;\n  height: 12px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n}\n", ""]);
// Exports
module.exports = exports;
