(<template>
  <header class="b-header">
    <span v-if="showUserCountry"
          class="b-header__country-test">{{ userCountry }}</span>
    <div class="b-header__logo-print"></div>
    <router-link v-if="!showBackBtn"
                 :to="$makeRoute({name: 'BuyerHome'})"
                 :class="{'b-header__logo--private': userIsNotEnterpriseMember}"
                 class="b-header__logo" />
    <button v-if="showBackBtn"
            class="b-header__back-btn"
            @click="historyBack">{{ $gettext('Back') }}</button>
    <template v-for="(menu, index) in leftMenu">
      <router-link v-if="menu.type === 'router-link' && menu.condition && !menu.html"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames">{{ menu.name }}</router-link>
      <router-link v-else-if="menu.type === 'router-link' && menu.condition && menu.html"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames"><span v-html="menu.html"></span></router-link>
      <button v-else-if="menu.type === 'button' && menu.condition"
              :key="index"
              :class="menu.classNames"
              @click="menu.action">{{ menu.name }}</button>
      <div v-else-if="menu.type === 'div' && menu.condition"
           :key="index"
           :class="menu.classNames"></div>
    </template>
    <header-nav-dropdown v-if="showNewEnterpriseHeader"
                         :dropdown-subtitle="interpretationDropdownSubtitle"
                         :links="interpretationDropdownLinks" />
    <header-nav-dropdown v-if="showNewEnterpriseHeader"
                         :dropdown-subtitle="translationDropdownSubtitle"
                         :links="translationDropdownLinks" />
    <book-menu-dropdown v-if="showNewEnterpriseHeader" />
    <div class="b-header__account-cont">
      <p v-if="$isGodmode()"
         class="sk-btn sk-btn--white b-header__godmode-btn">{{ $gettext('Admin mode') }}</p>
      <language-selector v-if="showNewEnterpriseHeader" />
      <a :href="'https://support.salita.no/collection/380-tolkebruker'"
         target="_blank"
         class="b-header__usual-link help-link">{{ $gettext('Help') }}</a>
      <button ref="accountBtn"
              :class="{'header__account-btn--redesign': userIsEnterpriseMember && enableNewHeaderForEnterprise,
                       'header__account-btn--redesign-active': subnavShown}"
              class="b-header__account-btn"
              @click.stop="toggleSubnav">
        <tt-avatar :own="true"
                   :class="{'b-header__nav-avatar--offset': userIsEnterpriseMember && enableNewHeaderForEnterprise}"
                   class="b-header__account-avatar" />
        <company-avatar v-if="!userIsNotEnterpriseMember"
                        :own="true"
                        class="b-header__account-logo" />
        <div v-if="userIsNotEnterpriseMember"
             class="hamburger-lines">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
      </button>
      <nav v-if="subnavShown"
           class="b-header__subnav"
           :class="{'header__subnav': userIsEnterpriseMember && enableNewHeaderForEnterprise}">
        <template v-for="(menu, index) in rightMenu">
          <div v-if="menu.type === 'enterprise-subnav-header' && menu.condition"
               :key="index">
            <h3 class="b-header__nav-subtitle">{{ $gettext('Profile') }}</h3>
            <router-link :to="profileLink"
                         class="subnav-profile-link">
              <tt-avatar :own="true"
                         class="b-header__account-avatar b-header__nav-avatar--offset" />
              <company-avatar v-if="!userIsNotEnterpriseMember"
                              :own="true"
                              class="b-header__account-logo" />
              <div class="subnav-profile-link__desription">
                <p class="subnav-profile-link__name">{{ fullName }}</p>
                <p class="subnav-profile-link__enterprise-name">{{ userEnterpriseName }}</p>
              </div>
              <div class="subnav-link__arrow-icon"></div>
            </router-link>
            <ul class="subnav-scope-selector">
              <li :class="{'subnav-scope-selector__item-active': enterpriseMemberJobsScope === 'all_jobs'}"
                  @click="setScopeJobs('all_jobs')">{{ $gettext('All jobs') }}</li>
              <li :class="{'subnav-scope-selector__item-active': enterpriseMemberJobsScope === 'department_jobs'}"
                  @click="setScopeJobs('department_jobs')">{{ $gettext('Department\'s jobs') }}</li>
              <li :class="{'subnav-scope-selector__item-active': enterpriseMemberJobsScope === 'participant_jobs'}"
                  @click="setScopeJobs('participant_jobs')">{{ $gettext('My jobs') }}</li>
            </ul>
          </div>
          <router-link v-if="menu.type === 'router-link' && menu.condition"
                       :key="index"
                       :to="menu.action"
                       :class="menu.classNames">
            <tt-avatar v-if="menu.hasAvatar"
                       :own="true"
                       class="b-header__nav-avatar" />
            <company-avatar v-if="menu.hasLogo"
                            :own="true"
                            class="b-header__nav-logo" />
            {{ menu.name }}</router-link>
          <button v-else-if="menu.type === 'button' && menu.condition"
                  :key="index"
                  :class="menu.classNames"
                  @click="menu.action">{{ menu.name }}</button>
          <a v-else-if="menu.type === 'link' && menu.condition"
             :key="index"
             :class="menu.classNames"
             :href="menu.href">{{ menu.name }}</a>
          <div v-else-if="menu.type === 'enterprise-subnav-lang-selector' && menu.condition"
               :key="index">
            <language-selector v-if="userIsEnterpriseMember && enableNewHeaderForEnterprise" />
          </div>
          <div v-else-if="menu.type === 'enterprise-subnav-footer' && menu.condition"
               :key="index"
               :class="menu.classNames">
            <div class="subnav-footer__external-links">
              <a target="_blank"
                 :href="privacyLink"
                 class="subnav-footer__external-link">{{ $gettext('Privacy') }}</a>
              <a target="_blank"
                 :href="termsLink"
                 class="subnav-footer__external-link">{{ $gettext('Terms & Conditions') }}</a>
            </div>
            <div class="subnav-footer__social">
              <p>{{ copyright }}</p>
              <div>
                <a class="subnav-footer__icon-link subnav-footer__icon-link--twitter"
                   target="_blank"
                   :href="twitterLink"></a>
                <a class="subnav-footer__icon-link subnav-footer__icon-link--facebook"
                   target="_blank"
                   :href="facebookLink"></a>
                <a class="subnav-footer__icon-link subnav-footer__icon-link--linkedin"
                   target="_blank"
                   :href="linkedinLink"></a>
              </div>
            </div>
          </div>
        </template>
      </nav>
    </div>
    <h1 v-if="!enableNewHeaderForEnterprise"
        class="b-header__page-name">{{ mobileTitle }}</h1>
  </header>
</template>)

<script>
  import {mapGetters, mapState, mapActions} from 'vuex';
  // import AdvertisementModal from '@/components/shared_components/modals/AdvertisementModal';
  import LanguageSelector from '@/components/dashboard_components/LanguageSelector';
  import BookMenuDropdown from '@/components/dashboard_components/BookMenuDropdown';
  import HeaderNavDropdown from '@/components/shared_components/HeaderNavDropdown';

  const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

  export default {
    components: {
      // 'advertisement-modal': AdvertisementModal
      'language-selector': LanguageSelector,
      'book-menu-dropdown': BookMenuDropdown,
      'header-nav-dropdown': HeaderNavDropdown
    },
    data() {
      return {
        openedSubnav: false,
        clientWidth: 768,
        showAdvert: !this.$isGodmode() && this.$store.getters['UserInfoStore/showAdvertisement']
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsInterpreter',
        'userIsNotEnterpriseMember',
        'userIsBusiness',
        'userIsEnterpriseMember',
        'enterpriseMemberJobsScope',
        'userCanShowStatistics',
        'fullName',
        'userEnterpriseName',
        'userCanShowStaffingButton',
        'allowInterpretationServices',
        'allowNewTranslationServices',
        'isEmailVerificationRequired',
        'showPowerBIPage',
        'kardemommeEnterprise'
      ]),
      ...mapGetters('FeatureFlagsStore', ['enableNewHeaderForEnterprise']),
      ...mapState('EnterpriseStore', {
        currentEmployee: (state) => state.currentEmployee || {}
      }),
      showNewEnterpriseHeader() { return this.userIsEnterpriseMember && this.enableNewHeaderForEnterprise && this.clientWidth >= 768; },
      privacyLink() { return this.$getString('domainData', 'privacyLink'); },
      facebookLink() { return this.$getString('social', 'facebookLink'); },
      twitterLink() { return this.$getString('social', 'twitterLink'); },
      linkedinLink() { return this.$getString('social', 'linkedinLink'); },
      termsLink() { return this.$getString('domainData', 'termLink'); },
      copyright() { return this.$getString('footer', 'copyright'); },
      profileLink() { return this.$makeRoute({name: 'BuyerUserProfileEdit', params: {id: this.userUid}}); },
      userCountry() { return this.$store.state.userCountry || 'countryNotDetected'; },
      showUserCountry() {
        // enable this cookie to show Business country
        if (isBrowser()) {
          return this.$cookieManager.getCookie('showUserCountry');
        }
      },
      leftMenu() {
        if (this.showNewEnterpriseHeader) {
          return this.enterpriseLeftMenu;
        } else if ((this.allowInterpretationServices === true && this.allowNewTranslationServices === true) || (this.userIsBusiness)) {
          return this.onBothLeftMenu;
        } else if (this.allowInterpretationServices === true && this.allowNewTranslationServices === false) {
          return this.interpretationOnlyLeftMenu;
        } else if (this.allowInterpretationServices === false && this.allowNewTranslationServices === true) {
          return this.newTranslationOnlyLeftMenu;
          // if flags are not defined or both are false ==> fallback to default
        } else {
          return this.interpretationOnlyLeftMenu;
        }
      },
      rightMenu() {
        if (this.userIsEnterpriseMember && this.enableNewHeaderForEnterprise) {
          return this.rightMenuForEnterpriseMember;
        } else if (this.allowInterpretationServices === true && this.allowNewTranslationServices === true) {
          return this.defaultRightMenu;
        } else if (this.allowInterpretationServices === true && this.allowNewTranslationServices === false) {
          return this.defaultRightMenu;
        } else if (this.allowInterpretationServices === false && this.allowNewTranslationServices === true) {
          return this.newTranslationOnlyRightMenu;
          // if flags are not defined or both are false ==> fallback to default
        } else {
          return this.defaultRightMenu;
        }
      },
      enterpriseLeftMenu() {
        return [
          {
            name: this.$gettext('Home'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__enterprise-link b-header__usual-link--first',
            action: {name: 'BuyerHome'},
            condition: true
          }
        ];
      },
      onBothLeftMenu() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerHome'},
            condition: true
          },
          {
            name: this.$gettext('My assignments'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.assignmentsLink,
            condition: true
          },
          {
            name: this.$gettext('Book a specific interpreter'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.capacityLink,
            condition: this.userIsEnterpriseMember && !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Book an interpreter'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'}),
            condition: true
          },
          // {
          //   name: '',
          //   type: 'div',
          //   classNames: 'b-header__devider',
          //   condition: true
          // },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerAllProjects'},
            condition: true
          },
          {
            name: this.$gettext('Order translation'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
            condition: true
          },
          {
            html: this.upgradeText,
            type: 'router-link',
            classNames: 'sk-btn sk-btn--white b-header__upgrade-btn',
            action: this.$makeRoute({name: 'BuyerUpgradeProfile'}),
            condition: this.userIsNotEnterpriseMember
          }
        ];
      },
      interpretationOnlyLeftMenu() {
        return [
          {
            name: this.$gettext('Home'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerHome'},
            condition: true
          },
          {
            name: this.$gettext('My assignments'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.assignmentsLink,
            condition: true
          },
          {
            name: this.$gettext('Book a specific interpreter'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.capacityLink,
            condition: this.userIsEnterpriseMember && !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Book an interpreter'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'}),
            condition: true
          },
          {
            html: this.upgradeText,
            type: 'router-link',
            classNames: 'sk-btn sk-btn--white b-header__upgrade-btn',
            action: this.$makeRoute({name: 'BuyerUpgradeProfile'}),
            condition: this.userIsNotEnterpriseMember
          }
        ];
      },
      newTranslationOnlyLeftMenu() {
        return [
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerAllProjects'},
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Order translation'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
            condition: true
          },
          {
            html: this.upgradeText,
            type: 'router-link',
            classNames: 'sk-btn sk-btn--white b-header__upgrade-btn',
            action: this.$makeRoute({name: 'BuyerUpgradeProfile'}),
            condition: this.userIsNotEnterpriseMember
          }
        ];
      },
      rightMenuForEnterpriseMember() {
        return [
          {
            name: '',
            type: 'enterprise-subnav-header',
            classNames: 'b-header__subnav-header',
            condition: true
          },
          {
            name: this.$gettext('My company'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--comp-info',
            action: this.$makeRoute({name: 'BuyerCompanyInfo'}),
            hasLogo: true,
            condition: !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Tickets'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--tickets',
            action: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}}),
            condition: !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--password',
            action: this.$makeRoute({name: 'BuyerChangePassword'}),
            condition: true
          },
          {
            name: this.$gettext('Notifications settings'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--settings',
            action: this.$makeRoute({name: 'BuyerPreferencesNotifications'}),
            condition: this.userIsBusiness || this.userIsEnterpriseMember
          },
          {
            name: this.$gettext('Accessibility'),
            type: 'button',
            classNames: 'header__subnav-link header__subnav-link--accessibility',
            action: this.openAccessibilityModal,
            condition: true
          },
          {
            name: this.$gettext('Video test'),
            type: 'router-link',
            classNames: 'header__subnav-link header__subnav-link--video-test',
            action: this.$makeRoute({name: 'BuyerTestVideo'}),
            condition: true
          },
          {
            name: '',
            type: 'enterprise-subnav-lang-selector',
            classNames: '',
            condition: this.isMobileView
          },
          {
            name: this.$gettext('Help'),
            type: 'link',
            classNames: 'header__subnav-link header__subnav-link--help-link',
            href: 'https://support.salita.no/collection/380-tolkebruker',
            target: '_blank',
            condition: this.isMobileView
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'header__subnav-link header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          },
          {
            name: '',
            type: 'enterprise-subnav-footer',
            classNames: 'header__subnav-footer',
            condition: true
          }
        ];
      },
      defaultRightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--my-profile',
            action: this.profileLink,
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('Accessibility'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--accessibility',
            action: this.openAccessibilityModal,
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--calendar',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Interpreters'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--interpreters',
            action: this.interpretersLink,
            condition: !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('My company'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--comp-info',
            action: this.$makeRoute({name: 'BuyerCompanyInfo'}),
            condition: !this.userIsNotEnterpriseMember && !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Payments'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--payments',
            action: this.$makeRoute({name: 'BuyerPayments'}),
            condition: this.userIsNotEnterpriseMember
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--password',
            action: this.$makeRoute({name: 'BuyerChangePassword'}),
            condition: true
          },
          // TODO: Original page is not working anymore and for proving everything is works suppliers must pass video test
          {
            name: this.$gettext('Network test'),
            type: 'link',
            classNames: 'b-header__subnav-link b-header__subnav-link--net-test',
            href: 'https://test.webrtc.org',
            target: '_blank',
            condition: false
          },
          {
            name: this.$gettext('Notifications'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--pref',
            action: this.$makeRoute({name: 'BuyerPreferencesNotifications'}),
            condition: this.userIsBusiness || this.userIsEnterpriseMember
          },
          {
            name: this.$gettext('Video test'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--video-test',
            action: this.$makeRoute({name: 'BuyerTestVideo'}),
            condition: true
          },
          {
            name: this.$gettext('Statistics'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--statistics',
            action: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}}),
            condition: this.userCanShowStatistics
          },
          {
            name: this.$gettext('Tickets'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--tickets',
            action: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}}),
            condition: !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      newTranslationOnlyRightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--my-profile',
            action: this.profileLink,
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('My company'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--comp-info',
            action: this.$makeRoute({name: 'BuyerCompanyInfo'}),
            condition: !this.userIsNotEnterpriseMember
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--password',
            action: this.$makeRoute({name: 'BuyerChangePassword'}),
            condition: true
          },
          {
            name: this.$gettext('Tickets'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--tickets',
            action: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}}),
            condition: true
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      interpretationDropdownLinks() {
        return [
          {
            text: this.$gettext('Interpretation assignments'),
            type: 'router-link',
            iconClass: 'interpretation-assignments-icon',
            action: this.assignmentsLink,
            condition: true
          },
          {
            text: this.$gettext('Calendar'),
            type: 'router-link',
            iconClass: 'calendar-icon',
            action: this.calendarLink,
            condition: true
          },
          {
            text: this.$gettext('Interpretation statistics'),
            type: 'router-link',
            iconClass: 'interpretation-statistics-icon',
            action: this.interpretationStatisticsLink,
            condition: this.userCanShowStatistics
          },
          {
            text: this.$gettext('Favorite interpreters'),
            type: 'router-link',
            iconClass: 'favorite-interpreters-icon',
            action: this.interpretersLink,
            condition: true
          },
          {
            text: this.$gettext('Book specific interpreter'),
            type: 'router-link',
            iconClass: 'book-specific-interpreter-icon',
            action: this.capacityLink,
            condition: !this.isEmailVerificationRequired
          }
        ];
      },
      translationDropdownLinks() {
        return [
          {
            text: this.$gettext('Translation assignments'),
            type: 'router-link',
            iconClass: 'translation-assignments-icon',
            action: this.$makeRoute({name: 'BuyerAllProjects'}),
            condition: true
          },
          {
            text: this.$gettext('Translation statistics'),
            type: 'router-link',
            iconClass: 'translation-statistics-icon',
            action: this.translationStatisticsStatisticLink,
            condition: this.userCanShowStatistics
          }
        ];
      },
      interpretationStatisticsLink() {
        return this.showPowerBIPage
          ? this.$makeRoute({name: 'BuyerPowerBI', query: {view: 'interpretation'}})
          : this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}});
      },
      translationStatisticsStatisticLink() {
        return this.showPowerBIPage && this.kardemommeEnterprise
          ? this.$makeRoute({name: 'BuyerPowerBI', query: {view: 'translation'}})
          : this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'translation'}, params: {chartId: 'line_type'}});
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'BuyerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD'),
            page: 1
          }
        });
      },
      homeLink() {
        return this.$makeRoute({
          name: 'BuyerHome'
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      interpretersLink() {
        return this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment().format('YYYY-MM-DD'),
            langFrom: '3'
          }
        });
      },
      mobileTitle() {
        return this.$store.state.mobileTitle || this.$getString('metaData', 'shortTitle');
      },
      subnavShown() {
        return this.openedSubnav;
      },
      showBackBtn() {
        return this.$store.state.subPage && this.$store.state.GlobalStore.touchScreen;
      },
      upgradeText() {
        return this.$gettext('Upgrade to<br />enterprise');
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      interpretationDropdownSubtitle() { return this.$gettext('Interpretation'); },
      translationDropdownSubtitle() { return this.$pgettext('translate', 'Translation'); },
    },
    methods: {
      ...mapActions('UserInfoStore', ['updateJobsScope']),
      setScopeJobs(value) {
        this.updateJobsScope(value);
      },
      closeModal() {
        this.$emit('closemodal');
      },
      openAccessibilityModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'accessibility-modal',
        });
      },
      toggleSubnav(e) {
        this.$store.commit('SelectsStore/closeOpenedSelect');
        this.toggleAbsoluteBlocks();
        this.openedSubnav = !this.openedSubnav;
        if (e.target.className.indexOf('b-header__account-') != -1) {
          this.$refs.accountBtn.blur();
        }
      },
      toggleAbsoluteBlocks(e) {
        if (e && (e.target.className.indexOf('b-header__account-') != -1 && e.target.className.indexOf('b-header__notifications-') != -1)) {
          return;
        }
        if (e && e.target.className.indexOf('language-dropdown') != -1) {
          return;
        }
        this.openedSubnav = false;
      },
      setClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      historyBack() {
        if (document.referrer && document.referrer.indexOf('sms') != -1) {
          this.$router.push('/');
        } else {
          const path = this.$route.fullPath;
          this.$router.back();
          setTimeout(() => {
            if (this.$route.fullPath == path) this.$router.push('/');
          }, 500);
        }
      },
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          // set cookie for preventing mobile app redirect after clicking on logout button
          this.$cookieManager.setCookie('preventMobileAppRedirect', true, {path: '/', expires: 2592000});
          setTimeout(() => {
            this.$goToExternalLoginPage();
          }, 0);
        });
      },
      closeAdvertisement() {
        this.showAdvert = false;
      }
    },
    mounted() {
      this.setClientWidth();
      document.addEventListener('click', this.toggleAbsoluteBlocks);
      window.addEventListener('resize', this.setClientWidth);
    }
  };
</script>
