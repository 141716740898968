(<template>
  <router-link :to="profileLink"
               class="nav-profile-link">
    <tt-avatar :own="true"
               class="nav-profile-link__avatar" />
    <p class="nav-profile-link__name">{{ fullName }}</p>
    <p class="sk-link nav-profile-link__link-imitation">{{ $gettext('View and edit profile') }}</p>
    <section v-if="userIsEnterpriseMember"
             class="nav-profile-link__enterprises-cont">
      <h2 class="nav-profile-link__enterprises-title">{{ $gettext('Associated with') }}</h2>
      <company-avatar :own="true"
                      class="nav-profile-link__enterprises-avatar" />
    </section>
    <section v-if="userIsBusiness"
             class="nav-profile-link__enterprises-cont">
      <router-link :to="$makeRoute({name: 'BuyerUpgradeProfile'})"
                   class="sk-btn sk-btn--white nav-profile-link__upgrade-btn">{{ $gettext('Upgrade to enterprise') }}</router-link>
    </section>
    <section v-if="$isGodmode()"
             class="nav-profile-link__admin-cont">
      <p class="sk-btn sk-btn--white nav-profile-link__godmode-btn"
         @click.prevent.stop="">{{ $gettext('Admin mode') }}</p>
    </section>
  </router-link>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsBusiness',
        'userIsEnterpriseMember',
        'userIsInterpreter',
        'fullName'
      ]),
      profileLink() {
        return this.$makeRoute({
          name: this.userIsInterpreter ? 'ServerProfileEdit' : 'BuyerUserProfileEdit',
          params: {id: this.userUid}
        });
      }
    }
  };
</script>

<style scoped>
  .nav-profile-link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
  }

  .nav-profile-link::after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    display: block;
    height: 1px;
    background-color: #e4e7ee;
  }

  .nav-profile-link__avatar {
    width: 100px;
    height: 100px;
  }

  .nav-profile-link__name {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .nav-profile-link__link-imitation {
    margin-bottom: 10px;
  }

  .nav-profile-link__enterprises-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .nav-profile-link__enterprises-title {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    color: #babec8;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
  }

  .nav-profile-link__enterprises-avatar {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }

  .nav-profile-link__upgrade-btn {
    width: auto;
    height: 35px;
    margin: 0 auto;
    padding: 0 10px;
    line-height: 35px;
  }

  .nav-profile-link__admin-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .nav-profile-link__godmode-btn {
    width: auto;
    height: 35px;
    margin: 0 auto;
    padding: 0 10px;
    line-height: 35px;
  }
</style>
