(<template>
  <banner :message="bannerMessage"
          class="contact-verification-banner">
    <button v-if="bannerType === 'phone'"
            class="sk-link contact-verification-banner__link"
            @click="openPhoneModal">{{ $gettext('Click here to add your phone number') }}</button>
  </banner>
</template>)

  <script>
  import Banner from '@/components/shared_components/banners/Banner';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      banner: Banner
    },
    props: {
      bannerType: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userEmail']),
      bannerMessage() {
        if (this.bannerType === 'email') {
          const template = this.$gettext('Is <a href="mailto:%{email}" class="sk-link js-copy-to-clipboard">%{email}</a> still your email address? We have sent you an email with a link to verify and have full access to your account.');
          return this.$gettextInterpolate(template, {email: this.userEmail});
        }

        return this.$gettext('Please enter and verify your phone number.');
      }
    },
    methods: {
      openPhoneModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'verify-phone',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Verify your phone number'),
            context: this,
            data: {
              phoneCode: this.phoneCode,
              phoneNumber: this.phoneNumber
            }
          }
        });
      }
    }
  };
  </script>

  <style scoped>
    .contact-verification-banner {
      width: calc(100% - 30px);
      margin: 15px auto 0;
    }

    .contact-verification-banner__link {
      margin-left: 10px;
    }

    @media (max-width: 767px) {
      .contact-verification-banner {
        width: 100%;
      }
    }
  </style>
