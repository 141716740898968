// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/undefined_imgs/warning_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.warning-banner[data-v-2456d795] {\n  margin: 15px;\n  padding: 15px 15px 15px 45px;\n  border: 1px solid #fede93;\n  border-radius: 8px;\n  background-color: #fffaed;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 17px center;\n  background-size: 17px;\n  background-repeat: no-repeat;\n  color: #333;\n  font-size: 14px;\n  line-height: 20px;\n  word-break: normal;\n}\n.warning-banner--sticky[data-v-2456d795] {\n  position: sticky;\n  top: 70px;\n  z-index: 5;\n}\n.warning-banner__message[data-v-2456d795] {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n}\n.warning-banner__link[data-v-2456d795] {\n  margin-left: 10px;\n  font-weight: bold;\n  text-decoration: underline;\n}\n", ""]);
// Exports
module.exports = exports;
