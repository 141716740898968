(<template>
  <banner :message="bannerMessage"
          class="upgrade-for-translation-banner">
    <button class="sk-link upgrade-for-translation-banner__link"
            @click="navigateToUpgradeEnterprise">{{ $gettext('Click here to upgrade') }}</button>
  </banner>
</template>)

  <script>
  import Banner from '@/components/shared_components/banners/Banner';

  export default {
    components: {
      banner: Banner
    },
    computed: {
      bannerMessage() {
        return this.$gettext('Please upgrade to Enterprise to place Translation orders.');
      }
    },
    methods: {
      navigateToUpgradeEnterprise() {
        this.$router.push(this.$makeRoute({name: 'BuyerUpgradeProfile'}));
      }
    }
  };
  </script>

  <style scoped>
    .upgrade-for-translation-banner {
      width: calc(100% - 30px);
      margin: 15px auto 0;
    }

    .upgrade-for-translation-banner__link {
      margin-left: 10px;
    }

    @media (max-width: 767px) {
      .upgrade-for-translation-banner {
        width: 100%;
      }
    }
  </style>
